export const ParseDate = (dateString: string): string | null => {

  if (!dateString) {
    return '01/1900/01';
  }

  let parts: string[];
  let separator = '/';

  // Check the separator in the date string
  if (dateString.includes('-')) {
    separator = '-';
  }

  // Split the date string based on the separator
  parts = dateString.split(separator);
  const firstPart = parseInt(parts[0], 10);
  const secondPart = parseInt(parts[1], 10);
  const thirdPart = parseInt(parts[2], 10);

  const cYear = firstPart.toString().length > 3 ? firstPart : thirdPart;

  const cMonth = firstPart.toString().length > 3
    ? (secondPart <= 12 ? secondPart : (secondPart > thirdPart ? thirdPart : secondPart))
    : (firstPart <= 12 ? firstPart : (firstPart > secondPart ? secondPart : firstPart));

  const cDay = firstPart.toString().length > 3
    ? (secondPart <= 12 ? thirdPart : (secondPart > thirdPart ? secondPart : thirdPart))
    : (firstPart <= 12 ? secondPart : (firstPart > secondPart ? firstPart : secondPart));

  return `${cYear}-${cMonth < 10 ? '0' + cMonth : cMonth}-${cDay < 10 ? '0' + cDay : cDay}`;

};
