import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Grid, IconButton, Paper, TextField, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAxios } from '../../hooks/useAxios';
import { CallNote, emptyCallNote } from '../../models/ServiceCall';
import { ApiResponse } from '../../models/ApiResponse';
import moment from 'moment';
import { FaEdit, FaPlusCircle, FaTrash } from 'react-icons/fa';
import { UtcToPst } from '../../utils/UtcToPst';
import { useAuth } from '../../hooks/useAuth';

type Props = {
  callid: string;
};

function NotesComponent({ callid }: Props) {

  const { setAuth, auth } = useAuth();
  const current_tech = auth?.guid;

  const axios = useAxios();
  const [notes, setNotes] = useState<CallNote[] | undefined>(undefined);
  const [noteContent, setNoteContent] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [editingNoteId, setEditingNoteId] = useState<string | null>(null);

  const fetchNotes = useCallback(() => {
    axios.get<ApiResponse<CallNote[]>>(`calls/notes?id=${callid}`)
      .then(res => {
        const apiResponse = res.data;
        setNotes(apiResponse.data);
      });
  }, [axios, callid]);

  useMemo(() => {
    if (!notes) {
      fetchNotes();
    }
  }, [fetchNotes, notes]);

  const saveNote = useCallback(() => {

    if (!noteContent.trim()) {
      alert("Note content cannot be blank.");
      return;
    }

    const noteData = editingNoteId
      ? { id: editingNoteId, note: noteContent, service_call_id: callid }
      : emptyCallNote(noteContent, callid);

    setLoading(true);
    axios.post<ApiResponse<CallNote>>(`calls/addorupdatenote`, JSON.stringify(noteData))
      .then(() => {
        alert(editingNoteId ? "Note updated successfully!" : "Note added successfully!");
      })
      .catch(() => {
        alert(editingNoteId ? "Error updating note." : "Error adding note.");
      })
      .finally(() => {
        setLoading(false);
        setEditingNoteId(null);
        setNoteContent("");
        fetchNotes();
      });
  }, [editingNoteId, noteContent, callid, axios, fetchNotes]);

  const handleDelete = useCallback(async (noteId: string | undefined) => {
    if (!noteId) {
      alert("Invalid note ID.");
      return;
    }
    try {
      const isConfirmed = window.confirm('Are you sure you want to delete this note?');
      if (isConfirmed) {
        await axios.delete(`calls/deletenote?id=${noteId}`);
        alert('Note deleted successfully!');
        fetchNotes();
      }
    } catch (error) {
      alert('Error deleting note.');
    }
  }, [axios, fetchNotes]);

  const startEdit = (noteId: string, content: string) => {
    setEditingNoteId(noteId);
    setNoteContent(content);
    setLoading(true);
  };

  const resetOperation = () => {
    setLoading(false);
    setEditingNoteId(null);
    setNoteContent("");
  };

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel11a-content"
        id="panel11a-header"
      >
        <Typography>Notes</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} sx={{ m: 2 }}>
          <Grid item sm={12} md={8}>
            <TextField
              multiline
              value={!editingNoteId ? noteContent : ''}
              label="Add Note"
              fullWidth
              rows={5}
              onChange={(e) => setNoteContent(e.target.value)}
              disabled={loading}
            />
          </Grid>
          <Grid item sm={12} md={2}>
            <Button
              onClick={saveNote}
              disabled={loading}
              variant="contained"
            >
              <FaPlusCircle style={{ marginRight: '1em' }} />
              Add
            </Button>
          </Grid>
        </Grid>
        <Divider />
        {notes?.map(note => (
          <Paper key={note.id} sx={{ m: 1, p: 1 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                <b style={{ fontSize: 'larger' }}>{note.user?.email}{" "}</b>
                ({UtcToPst(moment(note.created).toLocaleString())})
              </div>
              <div>
                { (note.user?.id === current_tech || auth?.access === "SuperAdmin") && (
                  <>
                  <Tooltip title="Edit Note">
                  <IconButton
                      aria-label="Edit"
                      onClick={() => startEdit(note.id!, note.note)}
                    >
                      <FaEdit style={{ color: '#0080FE' }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete Note">
                  <IconButton
                      aria-label="Delete"
                      onClick={() => handleDelete(note.id)}
                    >
                      <FaTrash style={{ color: '#e15b5b' }} />
                    </IconButton>
                  </Tooltip>
                  </>
                )}
              </div>
            </div>
            {/* UI for Update */}
            {editingNoteId === note.id ? (
              <div style={{ marginTop: '1em' }}>
                <TextField
                  multiline
                  value={noteContent}
                  fullWidth
                  rows={3}
                  onChange={(e) => setNoteContent(e.target.value)}
                />
                <Button
                  onClick={saveNote}
                  variant="contained"
                  sx={{ mt: 1 }}
                >
                  Save
                </Button>
                <Button
                  onClick={resetOperation}
                  variant="outlined"
                  sx={{ mt: 1, ml: 1 }}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <p style={{ padding: '0.5em' }}>{note.note}</p>
            )}
          </Paper>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default NotesComponent;
